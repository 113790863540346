import React from "react";
// SVG of the icon in nav when closed
export const NavClosedIcon = () => {
  return (
    <>
      <svg
        className="block h-6 w-6 text-purple-500"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M4 6h16M4 12h16M4 18h16"
        />
      </svg>
    </>
  );
};
