export const DIFFICULTIES = [
  {
    difficulty: "Easy",
    value: 50,
  },
  {
    difficulty: "Medium",
    value: 100,
  },
  {
    difficulty: "Hard",
    value: 150,
  },
  {
    difficulty: "Hell",
    value: 250,
  },
];

export const GAME_TIMES = [10, 15, 20];
